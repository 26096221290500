import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import english from './en.json'
import portugueseBrazil from './pt-br.json'
//TODO: detection and suggestions for users to change lang
//import LanguageDetector from 'i18next-browser-languagedetector'

export const resources = {
	en: {
		translation: english
	},
	pt: {
		translation: portugueseBrazil
	}
} as const

i18n
	//.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		//detection: {
		//order: ['localStorage', 'cookie', 'navigator'],
		//	caches: []
		//},
		fallbackLng: 'en',
		supportedLngs: ['en', 'pt'],
		//lowerCaseLng: true,
		//nonExplicitSupportedLngs: true,
		debug: false,
		lng: 'en',
		resources
	})
