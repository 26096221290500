import Logo from './Logo'
import Typography from '@mui/material/Typography'
import {Theme} from '@mui/material/styles'
import Stack from '@mui/material/Stack'

interface LogoTypeProps {
	logoSize: string
	typographyVariant:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline'
		| 'inherit'
		| undefined
	marginTopTypo?: string | number
}

const LogoType = ({
	logoSize,
	typographyVariant,
	marginTopTypo
}: LogoTypeProps) => {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<Logo sx={() => style.iconLogo(logoSize)} />
			<Typography
				variant={typographyVariant}
				component="h1"
				sx={theme => style.typo(theme, marginTopTypo)}
			>
				Excofy
			</Typography>
		</Stack>
	)
}

const style = {
	iconLogo: (size: string) => {
		return {
			height: size,
			width: size
		}
	},
	typo: (theme: Theme, marginTop?: string | number) => {
		return {
			color: theme.palette.grey[900],
			fontWeight: '700',
			paddingTop: marginTop ? marginTop : 1
		}
	}
}

export default LogoType
