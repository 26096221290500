import {create} from 'zustand'

interface AppStates {
	currentLang: string
	setLang: (lang: string) => void
}

const useAppStates = create<AppStates>()(set => ({
	currentLang: '',
	setLang: lang => set({currentLang: lang})
}))

export default useAppStates
