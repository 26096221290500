import {Outlet} from 'react-router-dom'
import Header from '../template/Header'
import CssBaseline from '@mui/material/CssBaseline'

const FrontLayout = () => {
	return (
		<>
			<Header />
			<CssBaseline />
			<Outlet />
		</>
	)
}

export default FrontLayout
