import {useState, useEffect} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {styled, Theme} from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Container from '@mui/material/Container'
import {FiMenu} from 'react-icons/fi'
import {CgClose as CloseIcon} from 'react-icons/cg'

import TabletUp from '../layout/responsive/TabletUp'
import Mobile from '../layout/responsive/Mobile'
import LogoType from './LogoType'

import {DASHBOARD_URL} from '../../config'
import LanguageMenu from './LanguageMenu'

const Header = () => {
	const {t, i18n} = useTranslation()

	const {pathname} = useLocation()
	const [value, setValue] = useState<number | false>(
		pathname === '/' || pathname === '' ? 0 : false
	)
	const [openMenu, setOpenMenu] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		i18n.changeLanguage('pt')
	}, [i18n])

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	return (
		<AppBar>
			<Toolbar variant="dense">
				<Container disableGutters sx={style.containerHeader}>
					<Link
						to="/"
						style={style.linkLogo}
						onClick={() => {
							setValue(0)
						}}
					>
						<LogoType logoSize="2.8rem" typographyVariant="h5" />
					</Link>
					<Nav>
						<Mobile>
							<MenuIcon
								onClick={() => setOpenMenu(true)}
								sx={theme => style.iconMenu(theme)}
								aria-label={t('open-menu')}
							/>
							<SwipeableDrawer
								anchor="right"
								open={openMenu}
								onClose={() => setOpenMenu(false)}
								onOpen={() => setOpenMenu(true)}
								PaperProps={{sx: style.drawerPaper}}
							>
								<Toolbar
									sx={style.closeMenu}
									onClick={() => setOpenMenu(false)}
								>
									<CloseIcon
										aria-label={t('close-menu')}
										style={style.iconClose}
									/>
								</Toolbar>
								<Tabs
									orientation="vertical"
									value={value}
									onChange={handleChange}
									sx={style.tabsSM}
									aria-label={t('main-menu')}
								>
									<NavTab
										label="Home"
										key="home"
										onClick={() => {
											if (pathname === '/' || pathname === '') {
												setOpenMenu(false)
											} else {
												navigate(`/`)
											}
										}}
									/>
									<NavTab
										label={t('login')}
										key="login"
										href={`${DASHBOARD_URL}/login`}
									/>
									<NavTab
										label={t('sign-up')}
										key="sign-up"
										href={`${DASHBOARD_URL}/sign-up`}
									/>
								</Tabs>
							</SwipeableDrawer>
						</Mobile>
						<TabletUp>
							<LanguageMenu />
							<Button
								href={`${DASHBOARD_URL}/login`}
								variant="outlined"
								sx={{mr: 1}}
							>
								{t('login')}
							</Button>
							<Button href={`${DASHBOARD_URL}/sign-up`} variant="contained">
								{t('sign-up')}
							</Button>
						</TabletUp>
					</Nav>
				</Container>
			</Toolbar>
		</AppBar>
	)
}

interface NavProps {
	children: React.ReactNode
}

const Nav = (props: NavProps) => {
	return (
		<Box ml="auto" component="nav" sx={{display: 'flex'}}>
			{props.children}
		</Box>
	)
}

interface TabProps {
	href?: string
	label: string
	onClick?: () => void
}

const MenuIcon = styled(FiMenu)``

const NavTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
	({theme}) => ({
		color: 'rgba(255, 255, 255, 0.7)',
		'&.Mui-selected': {
			color: '#fff'
		},
		'&.Mui-focusVisible': {
			backgroundColor: 'rgba(100, 95, 228, 0.32)'
		},
		fontSize: theme.typography.pxToRem(16),
		fontWeight: theme.typography.fontWeightRegular,
		'&:hover': {color: '#fff'},
		textTransform: 'none'
	})
)

const style = {
	closeMenu: {
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	containerHeader: {
		alignItems: 'center',
		display: 'flex',
		minHeight: '68px'
	},
	drawerPaper: {
		backgroundColor: '#000000d5',
		width: '50vw'
	},
	iconClose: {
		color: '#FFF',
		height: 23,
		width: 23
	},
	iconMenu: (theme: Theme) => {
		return {
			color: theme.palette.grey[900],
			height: 23,
			width: 23
		}
	},
	linkLogo: {
		textDecoration: 'none',
		width: 'max-content'
	},
	tabsSM: {
		'& .MuiTabs-indicator': {
			borderRadius: 4,
			left: 0,
			width: 4
		}
	}
}

export default Header
