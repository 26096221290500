import {useEffect, useState} from 'react'
import usePathLang from '../hooks/usePathLang'
import {useParams} from 'react-router-dom'
import i18n from 'i18next'
import {DEFAULT_LANG, VALID_LANG} from '../config'
import NotFound from '../pages/NotFound'

const I18nValidator = ({
	children
}: {
	children?: React.ReactNode
}): JSX.Element => {
	const [validRouteLang, setValidRouteLang] = useState(true)
	const [, setPathLang] = usePathLang()
	const {lang} = useParams()

	useEffect(() => {
		if (lang === undefined) {
			i18n.changeLanguage(DEFAULT_LANG)
			setPathLang('')
		} else {
			if (lang in VALID_LANG) {
				i18n.changeLanguage(VALID_LANG[lang])
				setPathLang('/' + lang)
			} else {
				setValidRouteLang(false)
			}
		}
		// eslint-disable-next-line
	}, [])

	return validRouteLang ? (children as React.ReactElement) : <NotFound />
}

export default I18nValidator
