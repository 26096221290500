import {Routes, Route} from 'react-router-dom'
import Index from '../pages/Index'
import Layout from '../components/layout/FrontLayout'
import Terms from '../pages/Terms'
import NotFound from '../pages/NotFound'
import I18nValidator from './I18nValidator'

const Router = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<I18nValidator>
						<Layout />
					</I18nValidator>
				}
			>
				<Route index element={<Index />} />
				<Route path="terms" element={<Terms />} />
			</Route>

			<Route
				path="/:lang"
				element={
					<I18nValidator>
						<Layout />
					</I18nValidator>
				}
			>
				<Route index element={<Index />} />
				<Route path="terms" element={<Terms />} />
			</Route>

			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default Router
