import ColorModeProvider from './ColorModeProvider'
import ThemeProvider from './ThemeProvider'
import LangProvider from './LangProvider'

const Providers = ({children}: {children?: React.ReactNode}) => {
	return (
		<ColorModeProvider>
			<ThemeProvider>
				<LangProvider>{children}</LangProvider>
			</ThemeProvider>
		</ColorModeProvider>
	)
}

export default Providers
