import {ThemeProvider as Theme} from '@mui/material/styles'
import {light} from '../themes/light'
import {dark} from '../themes/dark'
import useColorMode from '../hooks/useColorMode'

const ThemeProvider = ({children}: {children?: React.ReactNode}) => {
	const mode = useColorMode()
	return <Theme theme={mode === 'light' ? light : dark}>{children}</Theme>
}

export default ThemeProvider
