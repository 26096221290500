/* ---------- Language ---------- */
export const VALID_LANG: {[key: string]: string} = {
	'pt-BR': 'pt',
	pt: 'pt',
	en: 'en',
	'en-US': 'en'
}
export const DEFAULT_LANG = 'en'

export const DASHBOARD_URL = 'https://dashboard.excofy.com'
