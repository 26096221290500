import {createTheme, responsiveFontSizes} from '@mui/material/styles'

// Theme light palette
let theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#F1BB0C',
			light: '#F1BB0C',
			dark: '#e8b308',
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#1586ab'
		},
		grey: {
			'50': '#fafafa',
			'100': '#f5f5f5',
			'200': '#eeeeee',
			'300': '#e0e0e0',
			'400': '#bdbdbd',
			'500': '#9e9e9e',
			'600': '#757575',
			'700': '#616161',
			'800': '#424242',
			'900': '#212121'
		}
	}
})

// Theme light components
theme = createTheme(theme, {
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: '#fffffffc',
					boxShadow: 'none',
					borderBottomColor: theme.palette.grey[200],
					borderBottomStyle: 'solid',
					borderBottomWidth: 1
				}
			}
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true
			},
			styleOverrides: {
				root: {
					borderRadius: 0,
					boxShadow: 'none'
				}
			},
			variants: [
				{
					props: {variant: 'contained'},
					style: {
						color: theme.palette.grey[900],
						'&:hover': {
							backgroundColor: theme.palette.primary.dark,
							boxShadow: 'none'
						}
					}
				},
				{
					props: {variant: 'outlined'},
					style: {
						borderColor: theme.palette.grey[300],
						color: theme.palette.grey[800],
						'&:hover': {
							color: theme.palette.grey[900]
						}
					}
				}
			]
		}
	}
})

export const light = responsiveFontSizes(theme)
