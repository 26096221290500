import {useState, createContext} from 'react'

export const LangContext = createContext<[string, (pathLang: string) => void]>([
	'',
	() => {}
])

const LangProvider = ({children}: {children?: React.ReactNode}) => {
	const [pathLang, setPathLang] = useState<string>('')
	return (
		<LangContext.Provider value={[pathLang, setPathLang]}>
			{children}
		</LangContext.Provider>
	)
}

export default LangProvider
