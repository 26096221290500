import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import i18n from 'i18next'

import {VALID_LANG} from '../../config'
import {MdLanguage as IconLang} from 'react-icons/md'
import {RiArrowDownSFill as IconArrowDown} from 'react-icons/ri'
import MenuItem from '@mui/material/MenuItem'
import useAppStates from '../../stores/useAppStates'

const LanguageMenu = () => {
	const {t} = useTranslation()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const setLang = useAppStates(state => state.setLang)
	const navigate = useNavigate()

	const {pathname} = useLocation()

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleLanguageChange = async (lang: string) => {
		if (Object.keys(VALID_LANG).includes(lang)) {
			await i18n.changeLanguage(VALID_LANG[lang])
			setLang(lang)
			navigate(getURLTranslated(pathname, lang))
		}
	}

	return (
		<Box sx={{mr: 3}}>
			<Box sx={{display: 'flex'}}>
				<Tooltip title={t('language-menu')}>
					<IconButton
						onClick={handleClick}
						size="small"
						sx={{ml: 2, backgroundColor: 'transparent !important'}}
						aria-controls={open ? t('account-menu') : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
						<IconLang style={{width: '25px', height: '25px'}} />
						<IconArrowDown />
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="language-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
			>
				{[
					{
						label: 'Português',
						lang: 'pt-BR'
					},
					{
						label: 'English',
						lang: 'en'
					}
				].map(item => (
					<MenuItem
						key={item.label}
						onClick={() => {
							handleClose()
							handleLanguageChange(item.lang)
						}}
					>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</Box>
	)
}

const getURLTranslated = (pathname: string, lang: string) => {
	let path = pathname

	Object.keys(VALID_LANG).forEach(lang => {
		if (path.indexOf(`/${lang}`) === 0) {
			path = path.replace(`/${lang}`, '')
		}
	})
	return `/${lang}${path}`
}

export default LanguageMenu
