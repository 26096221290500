import FullSection from '../components/layout/utils/FullSection'
import SectionTitle from '../components/typography/SectionTitle'

const Index = () => {
	return (
		<>
			<FullSection grey={50} id="articles">
				<SectionTitle></SectionTitle>
			</FullSection>
			<FullSection grey={200} id="academic">
				<SectionTitle>Features</SectionTitle>
			</FullSection>
			<FullSection grey={100} id="projects">
				<SectionTitle>Prices</SectionTitle>
			</FullSection>
			<FullSection grey={900} height={800}>
				<SectionTitle>Footer</SectionTitle>
			</FullSection>
		</>
	)
}

export default Index
